import { IS_DEBUG } from "./constants/general";

export default function debug(...args) {
    if (IS_DEBUG) {
        console.log('DEV DEBUG ', ...args);
    }

    if (typeof window !== 'undefined') {
        setTimeout(() => {
            const logStore = window['__$fsll'] = window['__$fsll'] || [];
            logStore.push({ time: Date.now(), data: args });
            while (logStore.length > 100) {
                logStore.shift();
            }
        });
    }
}
