class PerformanceManager {
	metrics = new Map();
    
	set(metricName, metric) {
        this.metrics.set(metricName, metric);
	}

	get(metricName = '') {
        return this.metrics.get(metricName);
	}
}

export default new PerformanceManager();
