const routes = {
	HOME_PAGE: '/',
	SEARCH: '/search',
	CATEGORY: '/category/[category]',
	CAT: '/category',
	PRIVACY: '/privacy',
	ABOUT: '/about',
	CONTACT: '/contact',
	COOKIE: '/cookie-declaration',
	DNSMPI: '/do-not-sell-my-personal-information',
	SITEMAP: '/sitemap',
	JOBS: '/jobs',
	FAQ: '/faq',
	TERMS: '/terms',
	REDIRECT: '/redirect',
	PREFIX: '/p',
	PREFIX_SLASH: '/p/',
	PREFIX_SLUG: '/p/[slug]',
	CATEGORY_PAGE: '/c/[categoryName]/[categoryId]',
	CATEGORY_PAGE_PREFIX: '/c',
};

module.exports = { ...routes };
