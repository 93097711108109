import { EN_LOCALE } from "src/lib/constants/locales";
import TrackingReduxManager from "src/lib/managers/TrackingReduxManager";

const unslugify = (string = '') => {
	const locale = TrackingReduxManager.getEntry('locale');

	if (locale === EN_LOCALE) {
		string = string.replace(/(%\D)|(%$)/g, '%25');
	}

	return decodeURIComponent(
		string
			.replace(/-/g, ' ')
			.replace(/[a-z][a-z]*-?/g, ([f, ...rest]) => f + rest.join('').replace('-', ' '))
	);
};

export default unslugify;