export const CLIENT_FEED_LOAD = 'CFEEDLOAD';
export const IOS_INSTALLER = 'iosinst';
export const IOS_INSTALLER_TTL = 60000;
export const IOS_INSTALLER_TTL2 = 7200000;
export const REIGNN_UNIT_AMOUNT = 'reignn-unit-amount';
export const FEEDBACK_TOAST = 'feedback-toast';
export const SPEED_TEST_TOAST = 'speed-test-toast';
export const UNIQUE_SESSION_KEY = 'fs-un';
export const TRACK_ON_NAV = 'on-nav';
export const PERSIST_VENDOR_PROPS = 'v-props';
export const MINI_STORE = 'mini-s';
export const SESSION_START = 'se-st';