/**
 * Active Tests
 * 
 * test examples
 * client: {
		testId: 73,
		variants: ['n', '1', '2', '3', '4'],
		testBlock: 2,
		env: 'client',
		testWeight: 10,
		variantWeights: [50, 0, 50, 0, 0],
	}

	server: {
		testId: 140,
		variants: ['n', '1', '2'],
	}
 */

export const SB_SCROLL = {
	testId: 173,
	variants: ['n', '1'],
};

export const ActiveTests = [SB_SCROLL];
export const ActiveClientTests = [];
//
