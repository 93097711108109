import { IS_CLIENT } from "./constants/general";

function isJson(str) {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

const fetchXHRAsync = (url, options = {}) => {
	let request = null;
	if (!IS_CLIENT) {
		request = new global.XMLHttpRequest();
	} else {
		request = new XMLHttpRequest();
	}

	return new Promise((resolve, reject) => {
		request.onreadystatechange = () => {
			if (request.readyState !== 4) {
				return;
			}

			const headers = request.getAllResponseHeaders();
			const responseJSON = IS_CLIENT ? request.response : request.responseText;
			let responseData = isJson(responseJSON) ? JSON.parse(responseJSON) : {};
			if (request.status >= 200 && request.status < 300) {
				resolve({ ...responseData, status: request.status, ok: true, headers });
			} else {
				reject({ ...responseData, status: request.status, statusText: request.statusText, headers });
			}
		};

		request.open('GET', url, true);

		if (!IS_CLIENT) {
			request.setRequestHeader('Referrer', options.referrer);
			request.setRequestHeader('user-agent', options.userAgent);
			// request.setRequestHeader('X-Forwarded-Host', options.hostname);
			request.setRequestHeader('X-Forwarded-For', options.clientIp);
		}
		request.send();
	});
};

export const fetchXHR = fetchXHRAsync;
