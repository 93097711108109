const TrackingReduxManager = {
    data: {},
	setData: function(_data) {
		this.data = { ...this.data, ..._data };
	},
	getData: function() {
        return this.data;
    },
	getEntry: function(key = '') {
		return this.data[key];
	}
};

export default TrackingReduxManager;
