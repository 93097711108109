import QueryManager from 'src/lib/managers/QueryManager';
import clone from 'clone';
import { CLIENT_FEED_LOAD } from 'src/lib/constants/storage';
import PerformanceManager from 'src/lib/managers/PerformanceManager';
import { FILTERS, FIRST_FETCH_TIMESTAMP, INIT_SEARCH_TIMESTAMP, IS_CLIENT, PAGEVIEW_TIME, SESSION_ID_KEY } from 'src/lib/constants/general';
import debug from './logger';
import TrackingManager from 'src/lib/managers/TrackingManager';
import { fetchXHR } from './fetch-xhr';
import { roundToNearest } from './utils';
import TrackingReduxManager from './managers/TrackingReduxManager';
import { buildBaseUrls, isNewFeed } from './whoami';
import { getCookie } from './managers/StorageManager';
import { DE_LOCALE, EN_LOCALE } from './constants/locales';

const apiRoot = (forcedUrl = '') => forcedUrl || buildBaseUrls().apiUrl;

// No need to initialize by host
const contentRoot = process.env.CONTENT_URL;
const contentListURL = `${contentRoot}/list?`;
const specialDatesApiUrl = `${contentRoot}/get?`;

const NEW_API_URLS = {
	fr: {
		huntaprice: process.env.NEW_FR_HAP_API_URL,
		findsimilar: process.env.NEW_FR_FS_API_URL,
	},
	de: {
		huntaprice: process.env.NEW_DE_HAP_API_URL,
		findsimilar: process.env.NEW_DE_FS_API_URL,
	},
	en: {
		huntaprice: process.env.NEW_HAP_API_URL,
		findsimilar: process.env.NEW_FS_API_URL,
	},
};

const getLocaleNewApiUrl = () => {
	const locale = TrackingReduxManager.getEntry('locale');
	const whoami = TrackingReduxManager.getEntry('whoami');
	if (!whoami) {
		return process.env.NEW_HAP_API_URL;
	}
	const { DOMAIN_NAME } = whoami;
	if (!DOMAIN_NAME) {
		return process.env.NEW_HAP_API_URL;
	}
	if (!NEW_API_URLS[locale]) {
		return process.env.NEW_HAP_API_URL;
	}
	const localeUrls = NEW_API_URLS[locale];
	if (!localeUrls[DOMAIN_NAME]) {
		return process.env.NEW_HAP_API_URL;
	}
	const newUrl = localeUrls[DOMAIN_NAME];
	if (!newUrl) {
		return process.env.NEW_HAP_API_URL;
	}
	return newUrl;
};

// Need to initialize by host, fs or hap
let dataTransformer = data => data;
const productsAPI = () => {
	const locale = TrackingReduxManager.getEntry('locale');
	let newApiUrl = '';
	let endpointUrl = '/products/list?';
	if (isNewFeed()) {
		newApiUrl = getLocaleNewApiUrl();
		debug(`productsAPI newApiUrl`, newApiUrl);
		endpointUrl = '/offers/query?';
		dataTransformer = (data = {}) => {
			let toTransform = {
				transformed: {
					productAds: [],
					textAds: [],
					filters: {},
					relatedSearches: [],
					testData: {},
					vendorProps: {},
					// page
					// totalResults
				},
				raw: { ...data },
			};
			const { content, message, code, ok, status } = data;
			const { feed, relatedSearches = [], vendorProps = {}, page, filters = {}, test = {} } = content;
			const { products = [], texts = [] } = feed;
			const { productOffers = [] } = filters;
			const { brandsOffers = {}, priceBuckets = {}, isFreeShipping = {} } = productOffers;
			const { id, group, block, isTracking } = test;

			toTransform.transformed.productAds = [
				...products.map((product = {}) => {
					const toReturn = {
						...product,
						adType: product.offerType,
						clickUrl: product.destinationUrl,
						storeName: product.extra?.data?.store?.name,
						storeLogoUrl: product.extra?.data?.store?.logo,
						brandName: product.extra?.data?.brand?.name || null,
						currentPrice: locale === DE_LOCALE ? new Intl.NumberFormat('de-DE').format(product.price?.current) : product.price?.current,
						originalPrice: locale === DE_LOCALE && product.price?.original != null ? new Intl.NumberFormat('de-DE').format(product.price?.original) : product.price?.original,
						shippingPrice: product.shipping?.price || null,
						discount: product.price?.discount,
						currency: product.price?.currency?.id,
						currencySymbol: product.price?.currency?.symbol,
						isFreeShipping: (Array.isArray(product.shipping) ? product.shipping : [product.shipping])?.map(
							shipment => shipment?.price === 0
						),
						imageUrl: product.images?.[0].medium,
						primeEligible: product.extra?.data?.isPrime || null,
						category:
							Array.isArray(product?.categories?.[0]) && product.categories[0].length ? product.categories[0].pop() : null,
					};
					delete toReturn.destinationUrl;
					delete toReturn.offerType;
					delete toReturn.extra?.data?.store?.name;
					delete toReturn.extra?.data?.store?.logo;
					delete toReturn.extra?.data?.brand?.name;
					delete toReturn.price?.current;
					delete toReturn.price?.original;
					delete toReturn.price?.discount;
					delete toReturn.price?.currency?.id;
					delete toReturn.price?.currency?.symbol;
					delete toReturn.extra?.data?.isPrime;
					delete toReturn.categories?.[0];
					delete toReturn.extra?.data?.features;
					return toReturn;
				}),
			];
			toTransform.transformed.textAds = [
				...texts.map((text = {}) => {
					const toReturn = {
						...text,
						adType: text.offerType,
						clickUrl: text.destinationUrl,
						ratingObj: {
							rating: text?.ratingObj?.rating,
							ratingsAmount: text?.ratingObj?.count,
						},
					};
					delete toReturn.offerType;
					delete toReturn.destinationUrl;
					return toReturn;
				}),
			];
			toTransform.transformed.filters = {
				productAds: {
					brandName: brandsOffers,
					currentPrice: priceBuckets,
					isFreeShipping,
				},
			};
			toTransform.transformed.testData = {
				testId: id,
				testGroup: group,
				testBlock: block,
				trackTest: isTracking,
			};
			toTransform.transformed.relatedSearches = relatedSearches.map(search => search.text);
			toTransform.transformed.vendorProps = vendorProps;
			toTransform.transformed.page = page;

			// Request data
			toTransform.transformed.message = message;
			toTransform.transformed.code = code;
			toTransform.transformed.ok = ok;
			toTransform.transformed.status = status;
			//

			debug(`new feed toTransform`, toTransform);

			return toTransform.transformed;
		};
	}
	return `${apiRoot(newApiUrl)}${endpointUrl}`;
};

const getACLocale = () => {
	const locale = TrackingReduxManager.getEntry('locale');

	if (locale === EN_LOCALE) {
		return 'us';
	}

	return locale;
};

const contactAPI = () => `${apiRoot()}/contact/post`;
const singleProductAPI = () => `${apiRoot()}/product/get?`;
const siteMapAPI = () => `${apiRoot()}/sitemap/get`;
const trendingSearchesAPI = () => isNewFeed() ? `${getLocaleNewApiUrl()}/trendingSearches?` : `${apiRoot()}/trends/searches/list?`;
const subscribeNewsLetterAPI = () => `${apiRoot()}/subscribe/post?`;
const autoCompleteAPI = () =>
	`https://sug.${TrackingReduxManager.getEntry('whoami').FULL_DOMAIN.toLowerCase()}/v1/sug/?market=${getACLocale()}&eid=ecommv2&q=`;

const fetchProductData = (queryParams, options = {}) => fetchTemplate(productsAPI(), queryParams, url => fetchXHR(url, options), 'Api');

const fetchTemplate = (url, queryParams, fetchFunc, evtTitle) => {
	const initSearchTimeStamp = PerformanceManager.get(INIT_SEARCH_TIMESTAMP);
	const pageViewTime = PerformanceManager.get(PAGEVIEW_TIME);
	const params = queryParams;
	FILTERS.forEach(key => {
		if (!params[key]) {
			delete params[key];
		}
	});
	const apiParams = clone(params); // Is needed?
	apiParams.sessionId = apiParams.sessionId || getCookie(SESSION_ID_KEY);
	
	const strParams = QueryManager.stringify(apiParams);

	PerformanceManager.set(FIRST_FETCH_TIMESTAMP, Date.now());
	TrackingManager.trackEvent(`Before Fetch Data From ${evtTitle}`);
	debug(`Yahoo Flow: 2 Fetch products from feed`);
	return fetchFunc(url + strParams)
		.then(data => {
			data = dataTransformer(data);
			const response = { ...data, ok: data.ok, status: data.status };
			const trackObj = {
				eventValueInteger2: roundToNearest(Date.now() - PerformanceManager.get(FIRST_FETCH_TIMESTAMP)),
				eventValueInteger3: pageViewTime,
				eventValueString6: response.headers,
				eventValueString7: productsAPI() + strParams,
				page: response.page,
			};
			if (initSearchTimeStamp) {
				trackObj.eventValueInteger1 = roundToNearest(Date.now() - initSearchTimeStamp);
			}
			if (!IS_CLIENT) {
				trackObj.geo = getACLocale();
			}

			TrackingManager.trackEvent(!response.ok ? `Fetch Data From ${evtTitle} Fail` : `Fetch Data From ${evtTitle}`, trackObj);

			debug('got new products', apiParams);
			return response;
		})
		.catch((error = {}) => {
			const trackObj = {
				eventValueInteger2: roundToNearest(Date.now() - PerformanceManager.get(FIRST_FETCH_TIMESTAMP)),
				eventValueInteger3: pageViewTime,
				eventValueString6: error.headers,
				eventValueString7: productsAPI() + strParams,
				eventValueString9: JSON.stringify(error),
				eventValueString10: `${error.name} ${error.message}`,
			};
			if (initSearchTimeStamp) trackObj.eventValueInteger1 = roundToNearest(Date.now() - initSearchTimeStamp);

			TrackingManager.trackEvent(`Fetch Data From ${evtTitle} Fail Network Catch`, trackObj);
			// captureMessage(`API Error: ${error}`)
			console.error('error fetching products', error);
			return error;
		});
};

/**
 * Deprecated
 * @param {*} queryParams
 * @returns
 */
const fetchSingleProductData = queryParams => {
	const params = QueryManager.stringify(queryParams);
	return fetch(singleProductAPI() + params)
		.then(response => response.json())
		.then(response => {
			if (!response) return; // maybe say no more

			return response;
		})
		.catch(() => {
			console.error('error fetching single product');
		});
};

const fetchAutoCompleteData = query => {
	return fetch(autoCompleteAPI() + query)
		.then(response => response.json())
		.then(response => {
			if (!response) {
				return; // maybe say no more data
			}

			return response[1] ? response[1] : [];
		})
		.catch(() => {
			console.error('error fetching autocomplete');
		});
};

const sendMessage = formData => {
	return fetch(contactAPI(), {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(formData),
	})
		.then(response => response.json())
		.then(response => {
			if (!response) return;
			return response;
		})
		.catch(() => {
			console.error('error posting msg');
		});
};

const fetchSiteMap = () => {
	return fetch(siteMapAPI())
		.then(response => response.json())
		.then(response => {
			if (!response) return;

			return response;
		})
		.catch(() => {
			console.error('error fetching sitemap');
		});
};

/**
 * DEPRECATED
 */
const fetchContentList = queryParams => {
	let params = QueryManager.stringify({
		tags: queryParams.q,
		cid: queryParams.cid,
	});

	return new Promise((res, rej) => {
		fetch(contentListURL() + params)
			.then(response => {
				response
					.json()
					.then(data => {
						res(data);
					})
					.catch(err => rej(err));
			})
			.catch(() => {
				console.error('error fetching content');
			});
	});
};

const fetchTrendingSearchesAPI = (queryParams = {}, limit = 16) => {
	const currentTest = sessionStorage.getItem(CLIENT_FEED_LOAD);
	let testParams = {};
	if (currentTest) {
		try {
			testParams = JSON.parse(currentTest);
		} catch (e) {
			console.error('bad tparams', testParams, e);
		}
	}

	let params = QueryManager.stringify({
		tags: queryParams.q,
		cid: queryParams.cid,
		threshold: 100,
		limit,
		...testParams,
	});

	return new Promise((res, rej) => {
		fetch(trendingSearchesAPI() + params)
			.then(response => {
				response
					.json()
					.then(data => {
						res(data);
					})
					.catch(err => rej(err));
			})
			.catch(() => {
				console.error('error fetching trending searches');
			});
	});
};

const subscribeNewsLetter = (data, urlParams = {}) => {
	const strParams = QueryManager.stringify(urlParams);

	const bodyParams = {
		...data,
	};

	return fetch(subscribeNewsLetterAPI() + strParams, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(bodyParams),
	})
		.then(response => response.json())
		.then(response => {
			if (!response) {
				return;
			}
			return response;
		})
		.catch(() => {
			console.error('error subscribe');
		});
};

const fetchSpecialDatesDataApi = (queryParams = {}) => {
	let params = QueryManager.stringify({
		type: queryParams.type || 'holidays_amazon',
		slug: queryParams.slug || 'Halloween',
	});

	return new Promise((res, rej) => {
		fetch(specialDatesApiUrl + params)
			.then(response => {
				response
					.json()
					.then(data => {
						res(data);
					})
					.catch(() => rej(null));
			})
			.catch(() => {
				console.error('error fetching special dates');
			});
	});
};

module.exports = {
	fetchProductData,
	fetchSingleProductData,
	fetchAutoCompleteData,
	sendMessage,
	fetchContentList,
	fetchSiteMap,
	fetchTrendingSearchesAPI,
	trendingSearchesAPI,
	subscribeNewsLetter,
	fetchSpecialDatesDataApi,
};
