import { CLIENT_FEED_LOAD } from 'src/lib/constants/storage';
import { ActiveClientTests, ActiveTests } from 'src/lib/constants/tests';
import { getSession, setSession } from 'src/lib/managers/StorageManager';
import QueryManager from 'src/lib/managers/QueryManager';
import { IS_FORCE_REAL } from '../constants/general';

const TestsManager = class TestsManager {
	test = {};
	forcedTest = {};

	constructor() {
		if (typeof window === 'undefined') {
			return;
		}

		localStorage.removeItem(CLIENT_FEED_LOAD);

		const sessionTestData = getSession(CLIENT_FEED_LOAD);
		const sessionTestObj = sessionTestData ? JSON.parse(sessionTestData) : {};
		if (Object.keys(sessionTestObj).length > 0 && sessionTestObj.testId) {
			this.setCurrentTest(sessionTestObj);
		}

		if (this.shouldForceTest()) {
			this.forcedTest = this.getForcedTest();
			return;
		}
	}

	getForcedTest(ssrData) {
		if (typeof window !== 'undefined') {
			let { ftid, ftg } = QueryManager.parse(window.location.search);
			return { ftid, ftg };
		}
		if (ssrData && ssrData.urlParams) {
			let { ftid, ftg } = ssrData.urlParams; 
			return { ftid, ftg };
		}
		return { ftid: '', ftg: '' };
	}

	shouldForceTest() {
		let { ftid, ftg } = QueryManager.parse(window.location.search);
		if (ftid && ftg) {
			return true;
		}
	}

	getCurrentTest() {
		return this.test;
	}

	setCurrentTest(test) {
		const { testId, testGroup } = test;
		setSession(CLIENT_FEED_LOAD, JSON.stringify(test), 1);
		this.test = test;
		if (typeof window === 'object' && window.hj) {
			window.hj('trigger', `tid${testId}tg${testGroup}`);
		}
	}

	isInTest(testConst = null) {
		if (!testConst) {
			return false;
		}
		return this.isActiveTest(testConst.testId) && `${this.test.testId}` === `${testConst.testId}`;
	}

	isInTestGroup(testConst, group) {
		let is = false;

		if (!this.isActiveTest(testConst.testId)) {
			return is;
		}
		if (!testConst || (!group && group !== 0)) {
			return is;
		}

		if (+this.test.testId !== +testConst.testId) {
			return is;
		}
		if (Array.isArray(group)) {
			group.map(item => {
				if (testConst.variants[item] === this.test.testGroup) {
					is = true;
				}
				if (item === 'n' && this.test.testGroup === 'n') {
					is = true;
				}
			});
			return is;
		}
		return testConst.variants[group] === this.test.testGroup || (group === 'n' && this.test.testGroup === 'n');
	}

	isActiveTest(testId) {
		return Boolean(ActiveTests.find(test => `${test.testId}` === `${testId}`));
	}

	isActiveClientTest(testId) {
		return Boolean(ActiveClientTests.find(test => `${test.testId}` === `${testId}`));
	}

	experiment(testConst) {
		if (IS_FORCE_REAL) {
			return;
		}
	
		if (this.forcedTest && this.forcedTest.ftid) {
			const isForcedTestActiveClient = this.isActiveClientTest(this.forcedTest.ftid);
			if (isForcedTestActiveClient) {
				this.setCurrentTest({
					testId: this.forcedTest.ftid,
					testBlock: testConst.testBlock,
					testGroup: this.forcedTest.ftg,
				});
			}
			return;
		}
		const sessionTestData = getSession(CLIENT_FEED_LOAD);
		if (sessionTestData) {
			return;
		}

		const isActiveClientTest = this.isActiveClientTest(testConst.testId);
		if (!isActiveClientTest || testConst.env !== 'client' || !testConst.testWeight || !testConst.testId) {
			return;
		}
		const isEnterTest = Math.random() * 100 <= testConst.testWeight;
		if (!isEnterTest) {
			return;
		}
		const totalWeight = testConst.variantWeights.reduce((a, b) => a + b, 0);
		if (totalWeight != 100) {
			return;
		}

		const groupWeights = [];
		let currentGroup = 0;
		while (currentGroup < testConst.variants.length) {
			for (let i = 0; i < testConst.variantWeights[currentGroup]; i++) {
				groupWeights[groupWeights.length] = testConst.variants[currentGroup];
			}
			currentGroup++;
		}

		const groupWeight = Math.floor(Math.random() * totalWeight);

		if (!groupWeights[groupWeight]) {
			return;
		}

		const selectedUserTest = {
			testId: testConst.testId,
			testBlock: testConst.testBlock,
			testGroup: groupWeights[groupWeight],
		};
		this.setCurrentTest(selectedUserTest);
	}

	resetTest() {
		this.test = {};
		this.forcedTest = {};
	}
};

export default new TestsManager();
