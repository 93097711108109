import { IS_CLIENT, IS_OLD_FEED } from "./constants/general";
import TrackingReduxManager from "./managers/TrackingReduxManager";
import debug from 'src/lib/logger';
import { DE_LOCALE, EN_LOCALE, FR_LOCALE } from "./constants/locales";

export const HUNT_A_PRICE = 'huntaprice';
export const FIND_SIMILAR = 'findsimilar';

const isSite = (SITE_CONST, host) => {
	if (host) {
		return host.indexOf(SITE_CONST) > -1;
	}
	if (!IS_CLIENT) {
		return false;
	}
	return location.hostname.indexOf(SITE_CONST) > -1;
};

export const resolveHost = (host = '') => {
	const isHap = isSite(HUNT_A_PRICE, host);
	const lastPartHost = host.split('.').pop();
	let tld = lastPartHost;
	if (lastPartHost.indexOf(':') > -1) {
		tld = lastPartHost.substring(lastPartHost.indexOf('.'), lastPartHost.indexOf(':')); // to remove port in case of dev
		if (tld === 'localhost') {
			tld = 'com';
		}
	}

	return {
		APP_NAME: isHap ? 'HuntAPrice' : 'FindSimilar',
		DOMAIN_NAME: isHap ? HUNT_A_PRICE : FIND_SIMILAR,
		FULL_DOMAIN: isHap ? `HuntAPrice.${tld}` : `FindSimilar.${tld}`,
		isHap,
		isFS: !isHap,
		TLD: tld,
		host
	};
};

export const buildBaseUrls = () => {
	const whoami = TrackingReduxManager.getEntry('whoami');
	const locale = TrackingReduxManager.getEntry('locale');
	const { isHap, isFS } = whoami;

	const isDE = locale === DE_LOCALE;
	const isEN = locale === EN_LOCALE;
	const isFR = locale === FR_LOCALE;

	return {
		apiUrl: (() => {
			if (isDE && isHap) {
                debug(`whoami isDE && isHap`);
				return process.env.DE_HAP_API_URL;
			}
			if (isDE && isFS) {
                debug(`whoami isDE && isFS`);
				return process.env.DE_FS_API_URL;
			}

			if (isEN && isHap) {
                debug(`whoami isEN && isHap`);
				return process.env.HAP_API_URL;
			}

			if (isEN && isFS) {
                debug(`whoami isEN && isFS`);
				return process.env.FS_API_URL;
			}

			if (isFR && isHap) {
                debug(`whoami isFR && isHap`);
				return process.env.FR_HAP_API_URL;
			}

			if (isFR && isFS) {
                debug(`whoami isFR && isFS`);
				return process.env.FR_FS_API_URL;
			}

			return process.env.HAP_API_URL;
		})(),
		cheramiUrl: isHap ? process.env.HAP_CHERAMI_URL : process.env.FS_CHERAMI_URL,
		pixelsUrl: (() => {
			if (isDE && isHap) {
				debug(`whoami isDE && isHap`);
				return process.env.HAP_PIXELS_URL_DE;
			}
			return isHap ? process.env.HAP_PIXELS_URL : process.env.FS_PIXELS_URL;
		})(),
	};
};

export const isNewFeed = () => {
	if (IS_OLD_FEED) {
		return false;
	}

	return true;

	// const locale = TrackingReduxManager.getEntry('locale');
	// const whoami = TrackingReduxManager.getEntry('whoami');

	// if (!whoami || !locale) {
	// 	// probably will not enter here
	// 	return true;
	// }
	// return locale !== DE_LOCALE || IS_NEW_FEED;
};