import { DE_LOCALE, EN_LOCALE, FR_LOCALE } from './locales';

export const DEFAULT_HAP_CID_EN = '9454';
export const DEFAULT_HAP_CID_DE = '9455';
export const DEFAULT_HAP_CID_FR = '9455';

export const DEFAULT_FS_CID_EN = '5177';
export const DEFAULT_FS_CID_DE = '5177';
export const DEFAULT_FS_CID_FR = '5177';

export const DEFAULT_FS_CID_UK = '8180'; // DEPRECATED

export const DEFAULT_CIDS = {
	FS: {
		[EN_LOCALE]: DEFAULT_FS_CID_EN,
		[DE_LOCALE]: DEFAULT_FS_CID_DE,
		[FR_LOCALE]: DEFAULT_FS_CID_FR,
	},
	HAP: {
		[EN_LOCALE]: DEFAULT_HAP_CID_EN,
		[DE_LOCALE]: DEFAULT_HAP_CID_DE,
		[FR_LOCALE]: DEFAULT_HAP_CID_FR,
	},
};

export const WEBOX_HAP_CID_EN = '9475';
export const WEBOX_HAP_CID_DE = '9532';
export const WEBOX_CIDS = {
	[EN_LOCALE]: WEBOX_HAP_CID_EN,
	[DE_LOCALE]: WEBOX_HAP_CID_DE,
	[FR_LOCALE]: WEBOX_HAP_CID_EN,
};

export const COMPLAINT_CIDS = [
	'8095',
	'8663',
	'8662',
	'8661',
	'9818',
	DEFAULT_FS_CID_EN,
	DEFAULT_FS_CID_DE,
	DEFAULT_FS_CID_FR,
	DEFAULT_FS_CID_UK,
	DEFAULT_HAP_CID_EN,
	DEFAULT_HAP_CID_DE,
	WEBOX_HAP_CID_DE,
	WEBOX_HAP_CID_EN,
];
export const PREMIUM_CIDS = ['8850'];
export const ONE_LINE_DESIGN_CIDS = [];
export const DISCOVERY_CIDS = ['8688', '8895', '9289', '9249', '9248', '9533', '9259'];
export const TIKTOK_CIDS = ['9661'];
