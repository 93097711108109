const { DE_LOCALE } = require("./constants/locales");
const { default: TrackingReduxManager } = require("./managers/TrackingReduxManager");

const handleBackToTop = () => {
	// TODO - refactor
	const offset = 50;
	const backToTopEl = document.querySelector('.back-to-top');
	if (!backToTopEl) {
		return;
	}
	const checkBackToTop = () => {
		const scrollPercentage = calculateScrollPercentage();
		scrollPercentage >= offset ? backToTopEl.classList.add('show') : backToTopEl.classList.remove('show');
	};
	const calculateScrollPercentage = () => {
		const elm = document.body;
		const parent = elm.parentNode,
			pos = ((elm.scrollTop || parent.scrollTop) / (parent.scrollHeight - parent.clientHeight)) * 100;

		return pos;
	};
	window.requestAnimationFrame(checkBackToTop);
};

// /**
//  * Small utility for debouncing functions
//  * @param callback
//  * @param ttl
//  * @returns {function(*=): void}
//  */
//  export const debounce = (callback, ttl = 0) => {
// 	let timeoutId = null;
// 	return (...args) => {
// 		clearTimeout(timeoutId);
// 		timeoutId = setTimeout(() => {
// 			return callback(...args);
// 		}, ttl);
// 	};
// };

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, time, c) {
	var d, e;
	return function () {
		function h() {
			(d = null), c || (e = func.apply(f, g));
		}
		var f = this,
			g = arguments;
		return clearTimeout(d), (d = setTimeout(h, time)), c && !d && (e = func.apply(f, g)), e;
	};
}

function capitalize(string = '') {
	if (!string) {
		return '';
	}
	return string.trim().split(' ').map(s => s[0].toUpperCase() + s.slice(1)).join(' ');
}
function stripHTML(text = '') {
	return text.replace(/<[^>]*>?/gm, '');
}

function addCentsToPrice(price) {
	const priceStr = `${price}`;
	const priceLength = priceStr.length;
	return priceStr.substring(0, priceLength - 2) + '.' + priceStr.substring(priceLength - 2, priceLength);
}

function parsePrice(price = '', symbol = '') {
	const locale = TrackingReduxManager.getEntry('locale');

	if (!price) {
		return null;
	}
	
	const floatPrice = locale !== DE_LOCALE ? parseFloat(price) : price;
	return `${symbol}${floatPrice.toString()}`;
}

function seperateByComma(string = '') {
	return string.split(' ').join(', ');
}

function isIE() {
	let msie = navigator.userAgent.indexOf('MSIE ') > -1;
	return msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
}

function deleteKeysFromObj(arr, obj) {
	arr.forEach(key => {
		if (obj[key]) {
			delete obj[key];
		}
	});
	return obj;
}

function roundToNearest(number, roundTo = 500) {
	if (parseInt(number, 10) === number) {
		if (number < roundTo / 2) {
			return 100;
		}
		return Math.round(number / roundTo) * roundTo;
	}
	else return null;
}

function isBot(ua = '') {
	return ua.toLowerCase().indexOf('bot') > -1;
}

function uuid(prefix = '') {
    let dt = new Date().getTime();
    let string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
	
	return prefix + string;
}

module.exports = {
	parsePrice,
	addCentsToPrice,
	handleBackToTop,
	debounce,
	capitalize,
	stripHTML,
	seperateByComma,
	isIE,
	deleteKeysFromObj,
	roundToNearest,
	isBot,
	uuid,
};
