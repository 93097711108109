import Router from 'next/router';
import { CAT, CATEGORY_PAGE_PREFIX, PREFIX_SLUG, PREFIX, SEARCH } from 'src/lib/constants/routes';
import { getDefaultCid } from '../helpers';
import parse from '../prescripts/utils/parse';
import parseUrl from '../prescripts/utils/parseUrl';
import unslugify from '../prescripts/utils/unslugify';

export function encodeWithPlus(str) {
	return encodeURIComponent(str).replace(/%20/g, '+');
}

/**
 * Transforms a key-value object to an encoded query string
 * @param {Object.<string,any>} obj
 * @return {string}
 */
function encodeSearchParams(obj) {
	return Object.entries(obj)
		.reduce((uriStr, keyValue) => {
			let [key, value] = keyValue;
			if (Array.isArray(value)) {
				// Support array params
				value.forEach((v, i) => {
					if (typeof v !== 'undefined') {
						return uriStr.push(`${key}[${i}]=${encodeWithPlus(String(v))}`);
					}
				});
			} else {
				if (typeof value !== 'undefined') {
					uriStr.push(`${key}=${encodeWithPlus(String(value))}`);
				}
			}
			return uriStr;
		}, [])
		.join('&');
}

// function stripQueryStringAndHashFromPath(url = '') {
// 	return url.split('?')[0].split('#')[0];
// }

// TODO - check
function slugify(string = '') {
	const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
	const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
	const p = new RegExp(a.split('').join('|'), 'g');

	return string
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
		.replace(/&/g, '-and-') // Replace & with 'and'
		.replace(/--/g, '-')
		.replace(/---/g, '-')
		.replace(/----/g, '-')
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, ''); // Trim - from end of text
}

function isDynamicRoute(route, firstPartInUrl, lastPartInUrl) {
	let dynamicPath = PREFIX;
	if (firstPartInUrl === PREFIX) {
		dynamicPath = PREFIX;
	} else if (firstPartInUrl === CAT) {
		dynamicPath = CAT;
	} else if (firstPartInUrl === CATEGORY_PAGE_PREFIX) {
		dynamicPath = CATEGORY_PAGE_PREFIX;
		const secondPart = location.pathname.split('/')[2];
		return route === `${dynamicPath}/${secondPart}/${lastPartInUrl}`;
	}

	return route === `${dynamicPath}/${lastPartInUrl}`;
}

function removeTrailingSlash(url = '') {
	return url.replace(/(?:\/+(\?))/, '$1').replace(/\/+$/, '');
}

const QueryManager = {
	extractParsedQuery(router = {}, currentQuery = '') {
		const { query = {} } = router;
		const { slug = '', category = '', categoryName = '' } = query;
		let parsedQuery = {};
		if (slug || category || categoryName) {
			parsedQuery.q = this.unslugify(slug || category || categoryName);
			parsedQuery = { cid: getDefaultCid(), ...query, q: parsedQuery.q };
		}

		if (typeof location !== 'undefined') {
			let queryParams = this.parse(location.search);
			queryParams = { ...queryParams, cid: queryParams.cid || getDefaultCid() };
			const pathname = removeTrailingSlash(location.pathname);
			let lastPartInUrl = pathname.slice(pathname.lastIndexOf('/') + 1, pathname.length);
			const firstPartInUrl = pathname.split('/')[1];
			const isDynamic = isDynamicRoute(pathname, `/${firstPartInUrl}`, lastPartInUrl);
			// TODO - check
			let queryFromParamsOrQueryString = '';
			if (isDynamic) {
				if (categoryName) {
					// Category Pages (what son did)
					queryFromParamsOrQueryString = pathname.split('/')[2];
				} else {
					// Prefix Pages
					queryFromParamsOrQueryString = lastPartInUrl;
				}
			} else {
				// Q param in url
				queryFromParamsOrQueryString = queryParams.q;
			}
			if (currentQuery) {
				// Force Q
				queryFromParamsOrQueryString = currentQuery;
			}
			parsedQuery = { ...queryParams, q: this.unslugify(queryFromParamsOrQueryString) };
		}

		return parsedQuery;
	},

	unslugify,

	slugify,

	parseUrl,

	parse,

	stringify: obj => encodeSearchParams(obj),

	getRoutes() {
		if (!Router || !Router.router) {
			return {
				staticRoute: SEARCH,
				dynamicRoute: SEARCH,
			};
		}
		const { route, asPath = '', query } = Router.router;
		const { slug = '' } = query;
		let dynamicRoute = asPath;
		if (route === PREFIX_SLUG) {
			// For %20
			dynamicRoute = `${PREFIX}/${slugify(slug)}`;
		}
		const staticRoute = route;
		return {
			staticRoute,
			dynamicRoute,
		};
	},

	encodeSpecialChars(string = '') {
		return encodeURIComponent(string);
	},
};

export default QueryManager;
