/**
 *  Generate an encoded url
 * @param {string} url - domain with protocol/port and path
 * @param {Object.<string,*>} params - url params to encode
 * @return {*}
 */
export const buildUrl = (url = '', params = {}, isDeleteSpecialArgs = true) => {
	if (isDeleteSpecialArgs) {
		delete params.vprops;
	}
	const keys = Object.keys(params);
	if (url.endsWith('/')) {
		url = url.substr(0, url.length - 1);
	}
	return !keys.length ? url : `${url}?${keys.map(key => `${key}=${encodeURIComponent(params[key])}`).join('&')}`;
};
