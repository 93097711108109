import { ABOUT, CONTACT, COOKIE, FAQ, PRIVACY, TERMS } from './routes';

export const DEFAULT_TLD = 'com';
export const SESSION_ID_KEY = 'FRM_SESSION_ID';

export const COLORS_ARRAY = [
	'#2B97FA',
	'#E82E50',
	'#6CB0EE',
	'#E8CA2E',
	'#3453BE',
	'#2EC6E8',
	'#DD688E',
	'#32C36C',
	'#2BBCFA',
	'#6C81EE',
	'#2E57E8',
	'#2EA5E8',
	'#EC9806',
	'#E8452E',
	'#6F36CB',
	'#2B77FA',
];

export const mustlinks = [
	{ link: PRIVACY, title: 'Privacy Policy' },
	{ link: TERMS, title: 'Terms of Service' },
	{ link: ABOUT, title: 'About' },
	{ link: FAQ, title: 'FAQ' },
	{ link: CONTACT, title: 'Contact' },
	{ link: COOKIE, title: 'Cookie Declaration' },
];

export const popularsearched = [
	{ link: '/search/?q=laptops', title: 'Laptops' },
	{
		link: '/search/?q=bedspreads',
		title: 'Bedspreads',
	},
	{
		link: '/search/?q=smartphones',
		title: 'Smartphones',
	},
	{
		link: '/search/?q=nike+shoes',
		title: 'Nike Shoes',
	},
	{
		link: '/search/?q=x+box',
		title: 'X Box',
	},
	{
		link: '/search/?q=vacuum+cleaners',
		title: 'Vacuum Cleaners',
	},
];

export const YAHOO_PRODUCT = 'yp';
export const YAHOO_VENDOR = 'y';
export const WALLMART_PRODUCT = 'imp';
export const AMAZON_PRODUCT = '3';
export const EBAY_PRODUCT = '2';

export const CONNEXITY_PRODUCT = '1';
export const YAHOO_TEXT = 'ys';
export const EBAY_CPA = 'eb';
export const ALI_EXPRESS = '5';
export const KELKOO = '6';
export const VIGLINK = 'vig';
export const SOURCE_KNOWLEDGE = 'sk';
export const DISCOUNT_FEED = 'discount';

export const SEARCH_ICON_SVG = '/fresh/images/search-icon.svg';
export const CLOSE_ICON = '/shared/images/images_fs-icons_close.svg';

export const LOAD_MORE_PRODUCTS_PAGINATION = 'pagination';
export const LOAD_MORE_PRODUCTS_SCROLL = 'scroll';
export const LOAD_MORE_PRODUCTS_BUTTON = 'button';

export const RUSTY_THEME = 'rusty';
export const FRESH_THEME = 'fresh';
export const AMAZON_LOGO = '/shared/images/brands/amazon.png';
export const SHOPZILLA_LOGO = '/shared/images/brands/shopzilla.png';
export const EBAY_LOGO = '/shared/images/brands/ebay.png';
export const WAYFAIR_LOGO = '/shared/images/brands/wayfair.png';

export const SEARCH_BAR_INDEX = 0;
export const MAXIMUM_INDEX = 5;

// Performance Constants
export const INIT_SEARCH_TIMESTAMP = '0';
export const AFTER_FETCH_TIMESTAMP = '1';
export const PAGEVIEW_TIME = '2';
export const FIRST_FETCH_TIMESTAMP = '3';
export const LCP = '4';

export const PROJECT = 'findsimilar';
export const IS_DEBUG = typeof location !== 'undefined' && location.href.includes('tomer=1');
export const IS_FORCE_REAL = typeof location !== 'undefined' && location.href.includes('forceReal=1');
export const IS_NEW_FEED = typeof location !== 'undefined' && location.href.includes('newfeed=1');
export const IS_OLD_FEED = typeof location !== 'undefined' && location.href.includes('oldfeed=1');
export const IS_CLIENT = (() => {
	return typeof window !== 'undefined';
})();
export const IS_PROD = process.env.ENV === 'production' || typeof location !== 'undefined' && location.href.includes('isprod=1');
export const IS_SERVICE_WORKER = process.env.SERVICE_WORKER === 'true';

export const ELIGIBLE_FOR_EXIT_ON_BACK = ['/search'];
export const FILTERS = ['brands', 'minDiscount', 'minPrice', 'maxPrice', 'isFreeShipping'];

export const bfDate = 'Nov 27, 2020 00:00:00';
export const BF_DATES = {
	from: {
		dayOfMonth: 19,
		month: 11,
	},
	to: {
		dayOfMonth: 29,
		month: 11,
	},
};

export const cmDate = 'Nov 30, 2020 00:00:00';
export const CM_DATES = [
	{ day: 29, month: 11 },
	{ day: 30, month: 11 },
	{ day: 31, month: 11 },
	{ day: 1, month: 12 },
	{ day: 2, month: 12 },
];

export const NAV_EVENTS_TTL = Date.now() - 5000; // TODO

export const REQUEST_ID_PREFIX = 'FC-';

export const OFFSET_FROM_TOP = 40;