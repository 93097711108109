export const EN_LOCALE = 'en';
export const DE_LOCALE = 'de';
export const FR_LOCALE = 'fr';
export const COOKIE_BOT_DE = '3ec4e93d-772a-4dcc-99bd-37477046440e';
export const COOKIE_BOT_US = '85be3047-1eee-469f-89e8-7f7ace8cc474';
export const COOKIE_BOT_FR = '05b4a866-3d74-4507-b3fe-e10ed89c8912';
export const COOKIE_BOT_NON_US = '0977d5ea-9e5f-4668-a944-9d469515f526';
export const COOKIE_BOT_IDS = {
	[DE_LOCALE]: COOKIE_BOT_DE,
	[FR_LOCALE]: COOKIE_BOT_FR,
	'default': COOKIE_BOT_NON_US
};
export const CAPTCHA_CODES = {
	[DE_LOCALE]: DE_LOCALE,
	[FR_LOCALE]: FR_LOCALE,
	[EN_LOCALE]: EN_LOCALE
};