import {
	IS_CLIENT,
	IS_DEBUG,
	IS_PROD,
	IS_SERVICE_WORKER,
	NAV_EVENTS_TTL,
	PROJECT,
	YAHOO_PRODUCT,
	YAHOO_TEXT,
} from 'src/lib/constants/general';
import { debounce } from 'src/lib/utils';
import { cleanObject } from '../clean-object';
import ReactGA from 'react-ga';
import TrackingReduxManager from './TrackingReduxManager';
import { TRACK_ON_NAV, UNIQUE_SESSION_KEY } from '../constants/storage';
import { fetchLocal, getSession, setSession } from './StorageManager';
import { EVENTS } from '../constants/events';
import TestsManager from './TestsManager';
import { CATEGORY_PAGE } from '../constants/routes';
import Router from 'next/router';
import { buildUrl } from '../build-url';
import { getDefaultCid } from '../helpers';
import debug from 'src/lib/logger';
import { buildBaseUrls } from '../whoami';
import { TIKTOK_CIDS } from '../constants/cids';

// Initialize report urls
const cheramiUrl = () => buildBaseUrls().cheramiUrl;
const pixelsUrl = () => buildBaseUrls().pixelsUrl;
const ctoUrl = process.env.CTO_URL;
//

const GA_EVENTS = [
	'Open Search Bar Menu',
	'search',
	'Click top Related Searches',
	'Click Back To Top',
	'Search Icon Click',
	'Click Logo',
	'Open Filter Bar',
	'Click Apply Filters',
	'Category DropDown Click',
	'Click category nav',
	'Trending Search Click',
	'Close Filter Bar',
	'Infinite Scroll',
	'adclick',
];

/**
 * Will decide is there ad block detection to user
 */
const adBlockDetection = () => {
	import(/* webpackMode: "lazy" */ /* webpackChunkName: "adblockdetection" */ '../../../public/adblockdetection.js')
		.then(() => {
			isBlockingAds = typeof window !== 'undefined' && document.getElementById('LCOeDEdgPqZH') ? 'No' : 'Yes';
		})
		.catch(() => {
			isBlockingAds = 'Yes';
		});
};

/**
 * Holds all events as they are none-blocking
 * @type {*[]}
 */
let PENDING_TRACKING = [];
let isBlockingAds = 'NA';
if (IS_CLIENT) {
	adBlockDetection();
	ReactGA.ga('require', 'ec');
}

/**
 * Add events to dataLayer of google analytics
 */
//  function gtag() {
// 	window.dataLayer.push(arguments);
// }

// TODO - do something else and delete this
const getCategoryName = () => {
	let currentCategory = null;
	if (Router && Router.router && Router.pathname === CATEGORY_PAGE) {
		const categoryName = Router.router.query.categoryName.replace(/-/g, ' ');
		currentCategory = categoryName;
	}
	return currentCategory;
};

const getTrafficSourceId = params => {
	return (
		params.trafficSourceId ||
		params.gclid ||
		params.ob_click_id ||
		params.zm_click_id ||
		params.msclkid ||
		params.tb_click_id ||
		params.gbraid ||
		params.wbraid ||
		TrackingReduxManager.getEntry('sessionId')
	);
};

/**
 * Base request for any event submission
 * @return {{}}
 */
const baseRequest = () => {
	const params = TrackingReduxManager.getEntry('urlParams');
	const test = TestsManager.getCurrentTest();
	return {
		// Base Data
		appVersion: process.env.APP_VERSION,
		appName: TrackingReduxManager.getEntry('appName'),
		project: PROJECT,
		keyword: params.q || getCategoryName(),
		cid: TrackingReduxManager.getEntry('cid') || params.cid || getDefaultCid(),
		resolution: IS_CLIENT ? `${window.innerWidth}x${window.innerHeight}` : '',
		//geo: params.geo || 'us',
		sessionId: TrackingReduxManager.getEntry('sessionId'),
		requestId: TrackingReduxManager.getEntry('requestId'),
		page: params.page,
		// External Query Params
		trafficSourceId: getTrafficSourceId(params),
		utmCampaign: params.utm_campaign,
		utmContent: params.utm_content,
		utmMedium: params.utm_medium,
		utmSource: params.utm_source,
		utmTerm: params.utm_term,
		trafficSourceAdGroupId: params.adgroup_id,
		trafficSourceCampaignId: params.campaignid || params.campid,
		trafficSourceDevice: params.device,
		trafficSourceKeywordId: params.keyword,
		trafficSourceNetwork: params.network,
		trafficSourceMatchType: params.matchtype,
		trafficSourceAdExtensionId: params.feeditemid,
		traficSourceAdId: params.adid,
		// Interal Custom Vars
		eventValueInteger3: IS_CLIENT ? window.scrollY : '',
		eventValueInteger6: Date.now(),
		eventValueString1: params.theme || 'rusty', // in ssr always will be rusty
		eventValueString3: IS_SERVICE_WORKER && IS_CLIENT ? (fetchLocal('sw') || {}).version : '',
		eventValueString8: isBlockingAds,
		eventValueString9: TrackingReduxManager.getEntry('resolvedUrl'),
		...test,
	};
};

const trackAPI = payload => {
	let fetchServerHeaders = {};
	if (!IS_CLIENT) {
		fetchServerHeaders = {
			'user-agent': TrackingReduxManager.getEntry('userAgent'),
			'X-Forwarded-Host': TrackingReduxManager.getEntry('host'),
		};
	}

	fetch(`https://${buildUrl(cheramiUrl(), !IS_PROD || IS_DEBUG ? payload.map(event => event.action) : {})}`, {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json',
			...fetchServerHeaders,
		},
	}).catch(err => {
		console.error('Error sending report to CherAmi ', err);
	});
};

const trackingDebounced = debounce(() => {
	if (process.env.SILENCE_CHERAMI) {
		return;
	}

	trackAPI(PENDING_TRACKING);

	PENDING_TRACKING.forEach(event => {
		if (IS_PROD && IS_CLIENT && window.gtag && GA_EVENTS.indexOf(event.action) > -1) {
			window.gtag('event', event.action, {
				event_category: event.cid,
				event_label: event.productData ? event.productData.vendorId : null,
				value: event.keyword,
				event_callback: () => {
					if (event.action !== 'ProductAd Impression' && event.action !== 'ProductAd Viewability' && IS_DEBUG) {
						debug('GA sent', event.action);
					}
				},
				send_to: 'analytics',
				non_interaction: event.action !== 'adclick',
			});
		}
	});
	PENDING_TRACKING = [];
});

// Want to getSession and parse once in sendNavEvents func
const navEventsIterator = () => {
	let navEventsString = getSession(TRACK_ON_NAV) || '{}';
	const navEventsObj = navEventsString ? JSON.parse(navEventsString) : {};
	const navEventsArray = navEventsObj.navEvents || [];

	return {
		getNavEventsArray: () => navEventsArray,
		updateNavEventsArray: (newNavEventsArray = null) => {
			navEventsObj.navEvents = newNavEventsArray || navEventsArray;
			setSession(TRACK_ON_NAV, JSON.stringify(navEventsObj));
		},
	};
};

const TrackingManager = {
	processEvent: function (action, data = {}) {
		data = { ...baseRequest(), ...data, action };
		return data;
	},
	/**
	 * Send an event to cherami. All it does actually is push an event object to PENDING_TRACKING
	 * @param {string} action
	 * @param {{}} data - will override/add to the default event data
	 */
	trackEvent: function (action, data = {}) {
		PENDING_TRACKING.push(this.processEvent(action, data));
		trackingDebounced();
	},
	trackError: function (...args) {
		if (process.env.TRACK_ERRORS) {
			const [action, name, stack] = (() => {
				const isCaught = args[0] !== EVENTS.ERROR.UNCAUGHT;
				const errorObject = args.find(arg => arg instanceof Error) || {
					message: args[0],
					stack: args.slice(1).join('\n'),
				};
				return [isCaught ? EVENTS.ERROR.CAUGHT : EVENTS.ERROR.UNCAUGHT, errorObject.message, errorObject.stack];
			})();

			this.trackEvent(action, {
				name,
				eventValueString10: stack,
			});
		}
	},
	uniqueTrack: function (action, data = {}) {
		const key = UNIQUE_SESSION_KEY;
		const isInSession = getSession(key) === 'true';
		if (!isInSession) {
			this.trackEvent(action, data);
			setSession(key, 'true');
		}
	},
	trackOnNav: function (action, data = {}) {
		const onNavEvents = getSession(TRACK_ON_NAV) || '{}';
		const onNavEventsObj = onNavEvents ? JSON.parse(onNavEvents) : {};
		onNavEventsObj.navEvents = [...(onNavEventsObj.navEvents || []), { eventData: this.processEvent(action, data), t: Date.now() }];
		setSession(TRACK_ON_NAV, JSON.stringify(onNavEventsObj));
	},
	// send navigation events
	sendNavEvents: function () {
		const { getNavEventsArray, updateNavEventsArray } = navEventsIterator();
		const navEventsArray = getNavEventsArray();

		// Send on track events
		while (navEventsArray.length) {
			const event = navEventsArray.shift();
			if (event.t >= NAV_EVENTS_TTL) {
				PENDING_TRACKING.push(event.eventData);
				trackingDebounced();
			} else {
				debug('Expired Event', event.action, event.data);
			}
		}

		// Override TRACK_ON_NAV after send events
		updateNavEventsArray(navEventsArray);
	},
	postPixel: function (productData, eventProps) {
		const urlParams = { ...TrackingReduxManager.getEntry('urlParams') };
		const cid = TrackingReduxManager.getEntry('cid');
		let { id, vendorId, ecpc } = productData;
		ecpc = ecpc || null;
		const pixelData = cleanObject({
			...baseRequest(),
			ob_click_id: urlParams.ob_click_id,
			k_user_id: urlParams.k_user_id,
			zm_click_id: urlParams.zm_click_id,
			category: urlParams.category,
			geo: urlParams.geo,
			amz: urlParams.amz,
			sbclkid: urlParams.sbclkid,
			rtbclickid: urlParams.rtbclickid,
			vpricing: urlParams.vpricing,
			fbclid: urlParams.fbclid,
			gbraid: urlParams.gbraid,
			wbraid: urlParams.wbraid,
			clickId: id,
			vendorId,
			ecpc,
		});
		if (Router && Router.router && Router.router.query && Router.router.query.categoryId) {
			pixelData.keyword = '';
			pixelData.categoryId = Router.router.query.categoryId;
		}

		for (let [key, value] of Object.entries(urlParams)) {
			urlParams[key] = encodeURIComponent(value);
		}

		const src = buildUrl(pixelsUrl(), pixelData);
		const markup = `<iframe src="${src}" style="width: 1px;height: 1px;border: none;opacity: 0"></iframe>`;
		document.getElementsByTagName('body')[0].insertAdjacentHTML('beforeend', markup);
		this.trackEvent('adclick', {
			...eventProps,
			action: 'adclick',
			productData,
		});

		if (TIKTOK_CIDS.includes(cid)) {
			window.ttq.track('ClickButton', {
				content_id: id,
				query: TrackingReduxManager.getEntry('requestId'),
			});
		}

		if ((+cid === 8791 || +cid === 8790) && (vendorId === YAHOO_PRODUCT || vendorId === YAHOO_TEXT)) {
			document
				.getElementsByTagName('body')[0]
				.insertAdjacentHTML(
					'afterend',
					`<img src="https://www.facebook.com/tr?id=134523420219564&amp;ev=Purchase&amp;cd[currency]=USD&amp;cd[value]=0.1" height="1" width="1" style="display:none"></img>`
				);
		}

		if (IS_PROD && IS_CLIENT && window.fbq && +cid === 9480) {
			window.fbq('track', 'Purchase', {
				value: 0.01,
			});
			document
				.getElementsByTagName('body')[0]
				.insertAdjacentHTML(
					'afterend',
					`<img src="https://pixels.reignn.com/?datasource=ktest&event=C2SWidgetClick&pub_id=150764&sh_ing=1&domain=huntaprice.com&traffic_source=fb&utm_cust_1=${vendorId}" height="1" width="1" style="display:none"></img>`
				);
		}

		// ReactGA.event({
		// 	category: 'AdClick',
		// 	action: productData['adType'],
		// 	label: vendorId,
		// });

		// if (window.gtag) {
		// 	window.gtag('event', 'AdClick', { event_category: 'AdClick', event_label: vendorId, event_value: productData['adType'] });

		// 	// window.gtag('event', 'purchase', {
		// 	// 	affiliation: vendorId,
		// 	// });
		// }
		// ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
		// 	id,
		// 	affiliation: vendorId,
		// 	revenue: 1,
		// 	tax: 0,
		// 	shipping: 0,
		// 	coupon: productData['adType'],
		// });

		// ReactGA.event({
		// 	category: 'AdClick',
		// 	action: productData['adType'],
		// 	label: vendorId,
		// });

		ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
			id,
			affiliation: vendorId,
			revenue: 1,
			tax: 0,
			shipping: 0,
			coupon: productData['adType'],
		});
	},
	postBingPixel: function () {
		const urlParams = TrackingReduxManager.getEntry('urlParams');
		if (!urlParams.sbclkid) {
			return;
		}

		// cto bidder pixel
		fetch(buildUrl(ctoUrl, { type: 'l1', sbclkid: urlParams.sbclkid }), {
			method: 'GET',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		})
			.then(() => {})
			.catch(err => console.error('Error sending report to cto ', err));
	},
};

export default TrackingManager;
