import { bfDate, IS_CLIENT, IS_DEBUG } from './constants/general';
import _mapKeys from 'lodash/mapKeys';
import { detect } from 'detect-browser';
import TrackingReduxManager from './managers/TrackingReduxManager';
import { COOKIE_BOT_IDS, EN_LOCALE } from './constants/locales';
import { DEFAULT_CIDS } from './constants/cids';
import debug from 'src/lib/logger';
import { cleanObject } from './clean-object';
const browser = detect();

export const getDefaultCid = (currentWhoAmI = {}) => {
	const whoami = TrackingReduxManager.getEntry('whoami');
	const locale = TrackingReduxManager.getEntry('locale');
	const { isHap } = whoami || currentWhoAmI;
	return DEFAULT_CIDS[isHap ? 'HAP' : 'FS'][locale];
};

export const getCookieBotId = (locale = EN_LOCALE) => COOKIE_BOT_IDS[locale] || COOKIE_BOT_IDS['default'];

let scriptsLoaded = [];
export const addYahooScript = yahooBeacon => {
	const { ysid } = yahooBeacon;
	if (scriptsLoaded.indexOf(ysid) >= 0) {
		debug('Yahoo Flow: 5 (ERROR) script already loaded', ysid);
		// script already loaded
		return;
	}
	scriptsLoaded.push(ysid);

	nov22Doc(yahooBeacon);
	// newDoc(yahooBeacon);
	// oldDoc(yahooBeacon);
};

// Based on Nov 2022
const nov22Doc = yahooBeacon => {
	const { ysid, trafficSrc, clientId, impressionGuid, clarityId, rguid } = yahooBeacon;
	console.log(`TCL  yahooBeacon:`, yahooBeacon);
	if (IS_CLIENT && !window.selectTier) {
		debug('Yahoo Flow: 5 (ERROR) SelectTier Error, no window.selectTier', ysid, trafficSrc, clientId, impressionGuid);
		return;
	}
	debug('Yahoo Flow: 5 Select Tier init', {
		source_tag: trafficSrc,
		ysid,
		cid: clientId,
		ig: impressionGuid,
		select_tier: {
			clarityId,
			rguid,
		},
	});
	window.selectTier.init({
		test_mode: IS_DEBUG,
		source_tag: trafficSrc,
		ysid,
		cid: clientId,
		ig: impressionGuid,
		select_tier: {
			clarityId,
			rguid,
		},
	});
};

// Based on january 2022
// const newDoc = yahooBeacon => {
// 	const { ysid, trafficSrc, clientId, impressionGuid } = yahooBeacon;
// 	if (IS_CLIENT && !window.xmlp) {
// 		debug('Yahoo Flow: 5 (ERROR) XMLP Error, no window.xmlp', ysid, trafficSrc, clientId, impressionGuid);
// 		return;
// 	}
// 	debug('Yahoo Flow: 5 XMLP init', ysid, trafficSrc, clientId, impressionGuid);
// 	window.xmlp.init({ source_tag: trafficSrc, ysid, cid: clientId, ig: impressionGuid });
// };

// Based on before january 2022
// const oldDoc = yahooBeacon => {
// 	const { ysid, trafficSrc, clientId, impressionGuid } = yahooBeacon;
// 	let isPla = false;
// 	const $script = document.createElement('script');
// 	$script.innerHTML = `(function(w,d,t,x,m,l,p) {
// 				w['XMLPlusObject']=m;
// 				w[m]=w[m] || function() {
// 				(w[m].q=w[m].q||[]).push(arguments)},w[m].l=1*new Date();
// 				l=d.createElement(t),p=d.getElementsByTagName(t)[0];
// 				l.type="text/javascript";
// 				l.async=1;
// 				l.defer=1;
// 				l.src=x;
// 				p.parentNode.insertBefore(l,p)
// 			})(window,document,'script','https://s.yimg.com/ds/scripts/xmlp.js','xmlp');
// 			xmlp('init',"${trafficSrc}", "${ysid}");
// 			${
// 				isPla
// 					? `(function(w,d,t,x,m,l,p) {
// 				w['PLAObject'] = m;
// 				w[m] = w[m] || function() {
// 					(w[m].q = w[m].q || []).push(arguments)
// 				}, w[m].l = 1*new Date();
// 				l = d.createElement(t), p = d.getElementsByTagName(t)[0];
// 				l.type="text/javascript";
// 				l.async=1;
// 				l.defer=1;
// 				l.src=x;
// 				p.parentNode.insertBefore(l,p)
// 				})(window,document,'script','https://s.yimg.com/ds/scripts/pla.js','pla');
// 				pla('init',"${clientId}", "${impressionGuid}");`
// 					: ``
// 			}
// 				`;
// 	document.head.appendChild($script);
// };

export const addYahooBeacon = (yahooBeacon = {}) => {
	const { ysid, trafficSrc } = yahooBeacon;

	if (!ysid) {
		debug(`Yahoo Flow: 3 ERROR Render Yahoo beacon no ysid`, trafficSrc);
		return;
	}

	debug(`Yahoo Flow: 3 Render Yahoo beacon`, ysid);
	const $img = document.createElement('img');
	$img.innerHTML = `<img height='1' width='1' src='https://search.yahoo.com/beacon/geop/p?s=1197808038&ysid=${ysid}&traffic_source=${trafficSrc}' />`;
	document.body.appendChild($img.firstChild);
};

export const addReignnScript = () => {
	const $script = document.createElement('script');

	$script.src = 'https://data.reignn.com/Clients/Publishers/149413/Sites/findsimilar.com/findsimilar.com.js';
	$script.async = true;

	document.head.appendChild($script);
};

export const getProductTargetLabel = (product = {}) => {
	const { vendorId } = product;
	return browser.name === 'crios' && vendorId === 'eb' ? '_self' : '_blank';
};

export const addDataTagToObj = (props = {}) => {
	if (!props) {
		return {};
	}

	// const locale = TrackingReduxManager.getEntry('locale');
	// const { isHap } = TrackingReduxManager.getEntry('whoami');
	// if (locale === DE_LOCALE && isHap) {
	// 	props.appns = '';
	// 	props.k = '';
	// 	return _mapKeys(props, (value, key) => `data-${key}`);
	// }
	// if (locale === EN_LOCALE && isHap) {
	// 	delete props.appns;
	// 	delete props.k;
	// }
	return cleanObject(_mapKeys(props, (value, key) => (value || key === 'yiid' ? `data-${key}` : '')));
};

export const scrollToRef = ref => {
	const element = document.getElementById(ref);
	if (!element) {
		return;
	}
	window.scrollTo({
		top: element.offsetTop - 100,
		behavior: 'smooth',
	});
};

export const isBFDates = bfDates => {
	// from 19 to 27 countdown
	// from 27 to 28 24h countdown and text Ends in...
	// until 29 00:00 blackfriday design

	const date = new Date();
	const dayOfMonth = date.getDate();
	const month = date.getMonth() + 1;

	const bfD = new Date(bfDate);
	const bfDayOfMonth = bfD.getDate();
	const bfMonth = bfD.getMonth() + 1;

	if (
		bfDayOfMonth <= bfDates.to.dayOfMonth &&
		bfDayOfMonth >= bfDates.from.dayOfMonth &&
		bfMonth <= bfDates.to.month &&
		bfMonth >= bfDates.from.month &&
		dayOfMonth >= bfDates.from.dayOfMonth &&
		dayOfMonth <= bfDates.to.dayOfMonth &&
		month >= bfDates.from.month &&
		month <= bfDates.to.month
	) {
		return true;
	}
	return false;
};

export const isCMDates = cmDates => {
	let isCM = false;
	// from 29 to 30 countdown cybermonday
	// from 30 to 31 24h countdown and text Ends in...
	// until 02/12 cybermonday design

	const date = new Date();
	const dayOfMonth = date.getDate();
	const month = date.getMonth() + 1;

	cmDates.forEach(date => {
		if (date.day === dayOfMonth && date.month === month) isCM = true;
	});

	return isCM;
};

export const isIn24Hrs = date => {
	const twentyFourHinMS = 86400000;
	const _date = new Date(date).getTime();
	const now = new Date().getTime();

	if (!_date) return false;

	// Find the distance between now and the count down date
	const distance = _date - now;
	if (distance < 0 && twentyFourHinMS > distance * -1) return true; // in 24 hrs
	if (distance < 0 && twentyFourHinMS < distance * -1) return false; // more then 24 hrs
	return null;
};

export const properCase = (str = '') =>
	str
		.split(' ')
		.filter(c => c.trim())
		.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
		.join(' ');
