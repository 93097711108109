import { IS_CLIENT } from '../constants/general';
import { MINI_STORE } from '../constants/storage';

export const setSession = (sname, svalue) => {
	if (!IS_CLIENT) {
		return null;
	}
	sessionStorage.setItem(sname, svalue);
};

export const uniqueSession = (sname, svalue) => {
	if (!IS_CLIENT) {
		return null;
	}
	const isAlreadyStored = getSession(sname);
	if (!isAlreadyStored) {
		setSession(sname, svalue);
	}
};

export const getSession = sname => {
	if (!IS_CLIENT) {
		return null;
	}
	return sessionStorage.getItem(sname);
};

export const setCookie = (cname, cvalue, exdays, domain = '') => {
	if (!IS_CLIENT) {
		return null;
	}
	let expires = '';
	if (exdays) {
		var d = new Date();
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		expires = 'expires=' + d.toUTCString();
	}
	document.cookie = `${cname}=${cvalue};${expires};path=/;domain=.${domain};SameSite=Lax;`;
};

export const getCookie = (cname, cookies = '') => {
	const cookiesToSearchFrom = IS_CLIENT ? document.cookie : cookies;

	if (!cookiesToSearchFrom) {
		return null;
	}

	var name = `${cname}=`;
	var ca = cookiesToSearchFrom.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
};

export const deleteCookie = name => {
	document.cookie = `${name}=; Max-Age=-99999999;`;
};

export const storeLocal = (key, value) => {
	localStorage.setItem(key, JSON.stringify(value));
	return value;
};

export const fetchLocal = key => {
	const item = localStorage.getItem(key);
	if (typeof item !== 'undefined') return JSON.parse(item);
	return null;
};

export const removeLocal = key => {
	localStorage.removeItem(key);
};

// value must be object
export const setMiniStore = value => {
	const miniStore = getMiniStore();
	setSession(
		MINI_STORE,
		JSON.stringify({
			...miniStore,
			...value,
		})
	);
};

export const getMiniStore = () => {
	if (IS_CLIENT) {
		return JSON.parse(getSession(MINI_STORE) || '{}');
	}
	return JSON.parse(getCookie(MINI_STORE) || '{}');
};

export const getMoshe = cookie => {
	return JSON.parse(getCookie('moshe', cookie) || '{ moshe: 1 }');
};
