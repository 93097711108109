export const EVENTS = {
    ERROR: {
        CAUGHT: 'CAUGHT',
        UNCAUGHT: 'UNCAUGHT',
        ERROR_BOUNDARY: 'ERROR BOUNDARY'
    },
    GENERAL: {
        ENTER: 'User Enter Website',
        SLOW_CONN_OPEN: 'Slow Connection Open',
        SLOW_CONN_CLOSE: 'Slow Connection Close',
        DO_NOT_SELL_PERSONAL_INFO_BTN: 'Do Not Sell Personal Info Btn'
    }
};
