import HAS_URL from "./hasUrl";

const analyzeUrl = (href) => {
	if (typeof document === 'undefined') return {};
	if (HAS_URL) return new URL(href);
	const a = document.createElement('a');
	a.href = href;
	const searchParams = !a.search
		? []
		: a.search
				.substr(1)
				.split('&')
				.map(sp => sp.split('='));
	return {
		href,
		origin: a.origin,
		pathname: a.pathname,
		host: a.host,
		searchParams,
	};
};

export default analyzeUrl;