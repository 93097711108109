import analyzeUrl from "./analyzeUrl";
import HAS_URL from "./hasUrl";
/**
 * @param {string} [url] - complete url string including protocol and domain (defaults to location.href)
 * @return {{query: {}, url: string, pathname: string,origin:string}}
 */
 const parseUrl = (url) => {
	const urlObject = analyzeUrl(url || location.href);
	return {
		url: urlObject.origin + urlObject.pathname,
		host: urlObject.host,
		origin: urlObject.origin,
		pathname: urlObject.pathname,
		query: Array.from(urlObject.searchParams).reduce((queryObj, keyValue) => {
			let [key, value] = keyValue;
			value = decodeURIComponent(HAS_URL ? value.replace(/(%\D)|(%$)/g, '%25') : value.replace(/\+/g, ' '));
			if (key[0] === '[' && key[key.length - 1] === ']') {
				// Support array params
				const [arrayKey, arrayIndex] = key.split(/\[|\]/);
				if (!queryObj[arrayKey]) queryObj[arrayKey] = [];
				queryObj[arrayKey][+arrayIndex] = value;
			} else {
				queryObj[key] = value;
			}
			return queryObj;
		}, {}),
	};
};

export default parseUrl;