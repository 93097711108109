import { RUSTY_THEME } from 'src/lib/constants/general';

export const SET_DEFAULT_SETTINGS_DATA = 'SET_DEFAULT_SETTINGS_DATA';
export const SET_BEACON_MODE = 'SET_BEACON_MODE';
export const SET_COMPLAINT_MODE = 'SET_COMPLAINT_MODE';
export const SET_CURRENT_THEME = 'SET_CURRENT_THEME';
export const SET_NEW_REIGNN_LAYOUT = 'SET_NEW_REIGNN_LAYOUT';
export const SET_IS_DISCOVERY_MODE = 'SET_IS_DISCOVERY_MODE';
export const SET_BACK_TO_TOP = 'SET_BACK_TO_TOP';
export const SET_INF_SCROLL = 'SET_INF_SCROLL';
export const SET_CURRENT_CATEGORY_PAGE = 'SET_CURRENT_CATEGORY_PAGE';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_RESOLVED_URL = 'SET_RESOLVED_URL';
export const SET_SSR_DATA = 'SET_SSR_DATA';
export const SET_CURRENT_DEVICE = 'SET_CURRENT_DEVICE';
export const SET_WHO_AM_I = 'SET_WHO_AM_I';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_MOSHE = 'SET_MOSHE';

// REDUCERS
const initialState = {
	defaultSettingsData: {},
	geo: 'us',
	theme: RUSTY_THEME,
	cid: '5177',
	currentCategory: {},
	complaintMode: false,
	isDiscoveryMode: false,
	isBackToTop: false,
	currentPage: '',
	resolvedUrl: '',
	ssrData: {},
	currentDevice: { mobile: true, desktop: false },
	whoami: {},
	locale: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_DEFAULT_SETTINGS_DATA:
			return {
				...state,
				cid: action.payload.cid,
				defaultSettingsData: action.payload,
			};
		case SET_COMPLAINT_MODE:
			return {
				...state,
				complaintMode: action.payload,
			};
		case SET_INF_SCROLL:
			return {
				...state,
				allPages: action.payload,
			};
		case SET_CURRENT_THEME:
			return {
				...state,
				theme: action.payload,
			};
		case SET_IS_DISCOVERY_MODE:
			return {
				...state,
				isDiscoveryMode: action.payload,
			};
		case SET_BACK_TO_TOP:
			return {
				...state,
				isBackToTop: action.payload,
			};
		case SET_CURRENT_CATEGORY_PAGE:
			return {
				...state,
				currentCategory: action.payload,
			};
		case SET_CURRENT_PAGE:
			return {
				...state,
				currentPage: action.payload,
			};
		case SET_SSR_DATA:
			return {
				...state,
				ssrData: action.payload,
			};
		case SET_CURRENT_DEVICE:
			return {
				...state,
				currentDevice: action.payload,
			};

		case SET_WHO_AM_I:
			return {
				...state,
				whoami: action.payload,
			};

		case SET_LOCALE:
			return {
				...state,
				locale: action.payload,
			};
		case SET_RESOLVED_URL:
			return {
				...state,
				resolvedUrl: action.payload,
			};
		default:
			return state;
	}
}

// GETTERS
export const getDefaultSettingsData = state => state.defaultSettingsData;
export const getIsComplaintMode = state => state.complaintMode;
export const getCurrentTheme = state => state.theme;
export const getIsDiscoveryMode = state => state.isDiscoveryMode;
export const getIsBackToTop = state => state.isBackToTop;
export const getCurrentCategoryPage = state => state.currentCategory;
export const getCurrentPage = state => state.currentPage;
export const getSSRData = state => state.ssrData;
export const getCurrentDevice = state => state.currentDevice;
export const getWhoAmI = state => state.whoami.whoami; // in purpose is whoami inside whoami, need to use it in TrackingReduxManager

//ACTIONS
export const setDefaultSettingsData = payload => ({ type: SET_DEFAULT_SETTINGS_DATA, payload });
export const setComplaintMode = payload => ({ type: SET_COMPLAINT_MODE, payload });
export const setCurrentTheme = payload => ({ type: SET_CURRENT_THEME, payload });
export const setIsDiscoveryMode = payload => ({ type: SET_IS_DISCOVERY_MODE, payload });
export const setBackToTop = payload => ({ type: SET_BACK_TO_TOP, payload });
export const setCurrentCategoryPage = payload => ({ type: SET_CURRENT_CATEGORY_PAGE, payload });
export const setCurrentPage = payload => ({ type: SET_CURRENT_PAGE, payload });
export const setResolvedUrl = payload => ({ type: SET_RESOLVED_URL, payload });
export const setSSRData = payload => ({ type: SET_SSR_DATA, payload });
export const setCurrentDevice = payload => ({ type: SET_CURRENT_DEVICE, payload });
export const setWhoAmI = payload => ({ type: SET_WHO_AM_I, payload });
export const setLocale = payload => ({ type: SET_LOCALE, payload });
